import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";

import { Button } from "react-bootstrap";
import { GraficoGauge } from "./GraficoGauge";

import Metabolismobasale from "./MetabolismoBasale";
import Ripartizione from "./Ripartizione";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import serverInfo from "../../util/globals";
import InputText from "../../util/UI/InputText";
import AnnotatioAlert from "../../util/UI/AnnotatioAlert";
import '../../index.css'

let timeout=0; 
export default function SchedaCompCorp() {
    const [modificato, setmodificato] = useState(false)

    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata)

    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
   const [id ,setid] = useState(0)
   
   const [altezza ,setaltezza] = useState(0)
   const [peso ,setpeso] = useState(0)
   const [bmi ,setbmi] = useState(0)
   const [plbicipite ,setplbicipite] = useState(0)
   const [pltricipite ,setpltricipite] = useState(0)
   const [plsottoscapolare ,setplsottoscapolare] = useState(0)
   const [plsovrailiaca ,setplsovrailiaca] = useState(0)
   const [pltotale, setpltotale] = useState(0)
   const [circvita ,setcircvita] = useState(0)
   const [circfianchi ,setcircfianchi] = useState(0)
   const [percmassagrassa ,setpercmassagrassa] = useState(0)
   const [massagrassa ,setmassagrassa] = useState(0)
   const [massamagra ,setmassamagra] = useState(0)
   const [data ,setdata] = useState(new Date().toISOString().slice(0, 10))

    const [metabolismobasale, setmeabolismobasale]=useState(0)
    const [obiettivopeso,setobiettivopeso] = useState(0)
    const [carbo, setcarbo]=useState(0)
    const [prote, setprote]=useState(0)
    const [fcgobiettivopeso,setfcgobiettivopeso]=useState(0)


    const [bmr, setbmr]=useState(0)
    const [dee, setdee]=useState(0)

    function calculateage(dob, dataaquandocalcolareeta) { 
        var diff_ms = (dataaquandocalcolareeta?new Date(dataaquandocalcolareeta.split('/')[2]+'-'+dataaquandocalcolareeta.split('/')[1]+'-'+dataaquandocalcolareeta.split('/')[0]).getTime():Date.now) - new Date(dob.split('/')[2]+'-'+dob.split('/')[1]+'-'+dob.split('/')[0]).getTime();
        var age_dt = new Date(diff_ms); 
        var test=Math.abs(age_dt.getUTCFullYear() - 1970);
        return test
    }

    function azzeradatiinput(){
        //setaltezza(0)
        //setpeso(0)
        //setbmi(0)
        setdata(new Date().toISOString().slice(0, 10))
        //setobiettivopeso(0)
        //setcarbo(0)
        //setprote(0)
        setid(0)
    }

	useEffect( () =>{
        const formData = new FormData();
        formData.append('website', 'annotatio.online');
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
        formData.append('idanagrafica',anagselezionata.id);
        axios.post(serverInfo.name+"/getUltimaSchedaCC", formData)
        .then(response=>{
            setaltezza(response.data.altezza)
            setbmi(response.data.bmi)
            setcircfianchi(response.data.circfianchi)
            setcircvita(response.data.circvita)
            setdata(response.data.data.slice(0,10))
            setid(response.data.id)
            setmassagrassa(response.data.massagrassa)
            setmassamagra(response.data.massamagra)
            setpercmassagrassa(response.data.percmassagrassa)
            setpeso(response.data.peso)
            setplbicipite(response.data.plbicipite)
            setplsottoscapolare(response.data.plsottoscapolare)
            setplsovrailiaca(response.data.plsovrailiaca)
            setpltricipite(response.data.pltricipite)
            setobiettivopeso(response.data.obiettivopeso)
            setcarbo(response.data.carboidrati)
            setprote(response.data.proteine)
            setbmr(response.data.bmr)
            setdee(response.data.dee)
            setpltotale(parseInt(response.data.plbicipite)+parseInt(response.data.pltricipite)+parseInt(response.data.plsottoscapolare)+parseInt(response.data.plsovrailiaca))
            setmodificato(false)
        });
    }
    ,[])

    function salvaScheda() {

        if (data===''||data==='null'){
            Swal.fire({
                position:'center',
                title:'Inserire la data',
                showConfirmButton:false,
                //text:'Inserire la data',
                icon:'warning',
                timer:3000,
                padding:0,
                width:'auto'
            })
            return
        }
        if (altezza===''||altezza==='null'
        ||parseInt(altezza)<=0){
            Swal.fire({
                position:'center',
                title:'Inserire l\'altezza',
                showConfirmButton:false,
                //text:'Inserire la data',
                icon:'warning',
                timer:3000,
                padding:0,
                width:'auto',
               
            })
            return
        }
        if (peso===''||peso==='null'
        ||parseInt(peso)<=0){
            Swal.fire({
                position:'center',
                title:'Inserire il peso',
                showConfirmButton:false,
                //text:'Inserire la data',
                icon:'warning',
                timer:3000,
                padding:0,
                width:'auto'
            })
            return
        }
        if (bmi===''||bmi==='null'
        ||parseInt(bmi)<=0){
            alert("Inserire il BMI")
            return
        }
        // if (percmassagrassa===''||percmassagrassa==='null'
        // ||parseInt(percmassagrassa)<0){
        //     alert("Inserire la percentuale di massa grassa")
        //     return
        // }

	    const formData = new FormData();
        formData.append('website', 'annotatio.online');
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
	    formData.append('id', id);
		formData.append('data', data);

        formData.append('idanagrafica',anagselezionata.id);
       formData.append('altezza',altezza?altezza:0);
       formData.append('peso',peso?peso:0);
       formData.append('obiettivopeso',obiettivopeso?obiettivopeso:0);
       formData.append('carboidrati',carbo?carbo:0);
       formData.append('proteine',prote?prote:0);
       formData.append('bmi',bmi?bmi:0);
       formData.append('plbicipite',plbicipite?plbicipite:0);
       formData.append('pltricipite',pltricipite?pltricipite:0);
       formData.append('plsottoscapolare',plsottoscapolare?plsottoscapolare:0);
       formData.append('plsovrailiaca',plsovrailiaca?plsovrailiaca:0);
       formData.append('circvita',circvita?circvita:0);
       formData.append('circfianchi',circfianchi?circfianchi:0);
       formData.append('percmassagrassa',percmassagrassa?percmassagrassa:0);
       formData.append('massagrassa',massagrassa?massagrassa:0);
       formData.append('massamagra',massamagra?massamagra:0);
       formData.append('idutente','');

	      axios.post(serverInfo.name+'/aggiornaSchedaCC',formData)
	      .then((result) => {
            AnnotatioAlert({icon:'success'});
            
          })
          .catch(err => {
            alert(err)
                AnnotatioAlert({
                icon:"error",
                position:"center",
                title:err})
            });
    }

	function update(e) {
        if (e.target.name==='altezza'
        &&e.target.value
        &&parseInt(e.target.value)>=0){
            if (peso
                &&peso>0){
                    
                    setbmi((peso/((parseInt(e.target.value)*parseInt(e.target.value))/10000)).toFixed(2))
                }
                else{
                    setbmi(0)
                }
        }

        if (e.target.name==='peso'
        &&e.target.value
        &&parseInt(e.target.value)>=0){
            if (altezza
                &&altezza>0){
                    
                    setbmi((e.target.value/((parseInt(altezza)*altezza)/10000)).toFixed(2))
                }
                else{
                    setbmi(0)
                }
        }
        
            if (e.target.name==='plbicipite'
            ||e.target.name==='pltricipite'
            ||e.target.name==='plsottoscapolare'
            ||e.target.name==='plsovrailiaca'){
                setpltotale(parseInt(plbicipite)+parseInt(pltricipite)+parseInt(plsottoscapolare)+parseInt(plsovrailiaca))
            }
    }

    

    useEffect(() => {
       if (!modificato) {
            setmodificato(true)
         } else {
            clearTimeout(timeout); 
            timeout = setTimeout(() => {
            salvaScheda()
            },1000) 
        }
    },[
        data,
        altezza,
        peso,
        obiettivopeso,
        carbo,
        prote,
        // plbicipite,
        // pltricipite,
        // plsottoscapolare,
        // plsovrailiaca,
        // circvita,
        // circfianchi,
        // percmassagrassa,
        // massagrassa,
        // massamagra
    ])

	    return (
	    		<div key={keytorefresh} className="container-fluid ">
                    <div className="sezione">
                    <h4>
                        Scheda Composizione Corporea {id===0?'NUOVA':''} di <u>{anagselezionata.nome+' '+anagselezionata.cognome+' ('+ anagselezionata.sesso+')'}</u>
                        <Button onClick={() => azzeradatiinput()}>Nuova misurazione</Button>
                    </h4>
                    <div className="row ">
                    <h5>Data misurazione <input type="date" name="data" onChange={(e) => setdata(e.target.value)} value={data} /></h5>
                    &nbsp;&nbsp;
                    <h5>Età {calculateage(anagselezionata.datanascita,data)}</h5>
                    </div>
                    <div className="row">
                    <h4>BMI</h4>
                    </div>
                    <div className="row ">
                        
                        <br />
                        <InputText
                        label="Altezza cm"
                        bootstrapsize={"col-4"}
                        name={"altezza"}
                        onChange={(e) => {update(e); setaltezza(e.target.value)}}
                        placeholder={"Altezza"}
                        step={1}
                        type={"number"}
                        value={altezza} width={100}/>

                        <InputText
                        label="Peso kg"
                        bootstrapsize={"col-4"}
                        name={"peso"}
                        onChange={(e) => {update(e); setpeso(e.target.value)}}
                        placeholder={"Peso in Kg"}
                        step={.01}
                        type={"number"}
                        value={peso} width={100}/>
                        
                        <InputText
                        
                        label="BMI %"
                        bootstrapsize={"col-4"}
                        name={"bmi"}
                        onChange={(e) => {update(e); setpeso(e.target.value)}}
                        placeholder={"BMI %"}
                        step={.01}
                        type={"number"}
                        value={bmi} disabled={true} width={100} />
                            
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <GraficoGauge 
                        valore={parseFloat(bmi)} 
                        label="BMI"
                        redFrom="40"
                        redTo="50"
                        yellowFrom="25"
                        yellowTo="40"
                        greenFrom="18.5"
                        greenTo="25"
                        max="50"
                        min="1"
                        />
                        </div>
                        <div className="col-md-8">
                        {
                            bmi>0&&bmi<18.5?
                            anagselezionata.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/sottopesouomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/sottopesodonna.jpg" />
                            :""
                        }
                        {
                            bmi>=18.5&&bmi<25?
                            anagselezionata.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/normaleuomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/normaledonna.jpg" />
                            :""
                        }
                        {
                            bmi>=25&&bmi<30?
                            anagselezionata.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/mediouomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/mediodonna.jpg" />
                            :""
                        }
                        {
                            bmi>=30&&bmi<35?
                            anagselezionata.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/obesouomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/obesodonna.jpg" />
                            :""
                        }
                        {
                            bmi>=35?
                            anagselezionata.sesso==='M'?
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/estremamenteobesouomo.jpg" />
                            :
                            <img style={{width:'100%'}} src="https://annotatio.online/wp-content/uploads/2022/07/estrmamenteobesodonna.jpg" />
                            :""
                        }
                        </div>
                    </div>
                    <hr />
                    <Metabolismobasale altezza={altezza} 
                    setmeabolismobasale={setmeabolismobasale} 
                    obiettivopeso={obiettivopeso} setobiettivopeso={setobiettivopeso} 
                    setfcgobiettivopeso={setfcgobiettivopeso}
                    data={data?data:new Date().toISOString().slice(0, 10)} 
                    pesoreale={peso}
                    bmr={bmr} 
                    dee={dee} />
                    <hr />
                    <Ripartizione daripartire={metabolismobasale} obiettivopeso={obiettivopeso} 
                    carbo={carbo} prote={prote} setcarbo={setcarbo} 
                    setprote={setprote}
                    data={data}
                    altezza={altezza} />
                    {/* <Plicometria 
                    circfianchi={circfianchi} circvita={circvita}
                    massagrassa={massagrassa} massamagra={massamagra}
                    percmassagrassa={percmassagrassa} plbicipite={plbicipite}
                    plsottoscapolare={plsottoscapolare} plsovrailiaca={plsovrailiaca}
                    pltotale={pltotale} pltricipite={pltricipite} setcircfianchi={setcircfianchi}
                    setcircvita={setcircvita} setmassagrassa={setmassagrassa} setmassamagra={setmassamagra}
                    setpercmassagrassa={setpercmassagrassa} setplbicipite={setplbicipite} 
                    setplsottoscapolare={setplsottoscapolare} setplsovrailiaca={setplsovrailiaca}
                    setpltotale={setpltotale} setpltricipite={setpltricipite} update={update} /> */}

			</div>
	    );
	}