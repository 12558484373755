import axios from 'axios';
import serverInfo from '../util/globals';

export async function getMessaggi(email, palestra, idanag) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('website', 'annotatio.online');
  formData.append('lic', '000000000000');
  formData.append('palestra', palestra);
  formData.append('id', idanag);
  const response = await axios.post(
    serverInfo.namesec+'/msg/listaMessaggiRest',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function getMessaggiOrdine(email, palestra, idordine) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('website', 'annotatio.online');
  formData.append('lic', '000000000000');
  formData.append('palestra', palestra);
  formData.append('idordine', idordine);
  const response = await axios.post(
    serverInfo.namesec+'/msg/listaMessaggiRest',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function getMessaggiadmin(email, palestra, filtro) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('website', 'annotatio.online');
  formData.append('lic', '000000000000');
  formData.append('palestra', palestra);
  formData.append('filtro', filtro);
  const response = await axios.post(
    serverInfo.name+'/listaMessaggiAdminRest',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function getCountNonLetti(email, palestra, idanag) {
  //console.log('getCountNonLetti');
  const formData = new FormData();
  formData.append('email', email);
  formData.append('website', 'annotatio.online');
  formData.append('lic', '000000000000');
  formData.append('palestra', palestra);
  formData.append('id', idanag);
  const response = await axios.post(
    serverInfo.name+'/getCountNonLetti',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
 // console.log(response);
  return response.data;
}

export async function inviaMessaggio(email, palestra, idanag, messaggio, tutti) { 
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email', email);
  formData.append('lic', '00000000');
  formData.append('palestra', palestra);
  formData.append('msg', messaggio);
  formData.append('idAnagrafica', idanag);
  if (tutti&&tutti===1){
    formData.append('tutti', tutti);
  }

  const response = await axios.post(
    serverInfo.namesec+'/msg/inviaMessaggiRest',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function inviaMessaggioOrdine(email, palestra, idanag, idordine, messaggio) { 
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email', email);
  formData.append('lic', '00000000');
  formData.append('palestra', palestra);
  formData.append('msg', messaggio);
  formData.append('idAnagrafica', idanag);
  formData.append('idordine', idordine);

  const response = await axios.post(
    serverInfo.namesec+'/msg/inviaMessaggiRest',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function inviaMessaggioWA(email, palestra, idanag, messaggio, tutti) { 
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email', email);
  formData.append('lic', '00000000');
  formData.append('palestra', palestra);
  formData.append('msg', messaggio);
  formData.append('idAnagrafica', idanag);
  formData.append('tutti', 0);
  formData.append('libero', 1);

  const response = await axios.post(
    serverInfo.namesec+'/msg/inviawaRest',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function inviaMessaggioWALibero(email, palestra, idanag, messaggio, tutti) { 
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email', email);
  formData.append('lic', '00000000');
  formData.append('palestra', palestra);
  formData.append('msg', messaggio);
  formData.append('idAnagrafica', idanag);
  formData.append('tutti', 0);
  formData.append('libero', 1);



  const response = await axios.post(
    serverInfo.namesec+'/msg/inviawaRest',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response);
  return response.data;
}