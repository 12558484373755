import {createSlice} from '@reduxjs/toolkit';

export const gestoreSchedulazioneSlice = createSlice({

    
  name: 'gestoreSchedulazioneSlice',
  initialState: {

    show:null ,
    togglePopupDettaglio:null,
    togglePopup:null,
    corsi:[],
    schedulazione:null,
    eventoselezionato:null,
    refresh:null,
    calendario:null,
    giornoselezionato:null,
    oraselezionata:null
  },
  reducers: {
    setshow: (state, show) => {
      state.show =  null;
      state.show =  show.payload;
    },
    settogglePopupDettaglio: (state, togglePopupDettaglio) => {
      state.togglePopupDettaglio =  null;
      state.togglePopupDettaglio =  togglePopupDettaglio.payload;
    },
    settogglePopup: (state, togglePopup) => {
      state.togglePopup =  null;
      state.togglePopup =  togglePopup.payload;
    },

    setcorsischedulazione: (state, corsi) => {
      state.corsi =  corsi.payload;
    },
    setsched: (state, schedulazione) => {
      state.schedulazione =  schedulazione.payload;
    },
    seteventoselezionato: (state, eventoselezionato) => {
      state.eventoselezionato = eventoselezionato.payload;
    },
    setrefresh: (state, refresh) => {
      state.refresh = refresh.payload;
    },
    setcalendario: (state, calendario) => {
      state.calendario = calendario.payload;
    },
    setgiornoselezionato: (state, giornoselezionato) => {
      state.giornoselezionato = giornoselezionato.payload;
    },
    setoraselezionata: (state, oraselezionata) => {
      state.oraselezionata = oraselezionata.payload;
    },
  },
});

export const {setshow, settogglePopupDettaglio, setcorsischedulazione, setsched, 
  seteventoselezionato, setrefresh, setcalendario,
  setgiornoselezionato, setoraselezionata, settogglePopup
} =
gestoreSchedulazioneSlice.actions;

export default gestoreSchedulazioneSlice.reducer;
