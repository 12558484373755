import React from "react";
import { useSelector } from 'react-redux';
import InputText from "../../util/UI/InputText";
import { calculateage, metabolismo, pesoideale } from "../../util/nutrizionista/calcoli";
import { useEffect } from "react";
import { useState } from "react";

export default function Ripartizione({obiettivopeso, daripartire, carbo, setcarbo, prote, setprote, data, altezza}){

    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);
    const palestra= useSelector(state => state.gestoreUtente.palestraassociata.palestra);

    const [metabo, setmetabo] = useState(0)
   
    function calcprote(){
        return Math.round((prote*obiettivopeso*4)*100)/100;
    }

    function calccarb(){
        return Math.round((carbo*obiettivopeso*4)*100)/100;
    }

    function calcgrassi(){
        return Math.round((metabo-calcprote()-calccarb())*100)/100;
    }

    function grassiqta(){
        return Math.round((calcgrassi()/9/obiettivopeso)*100)/100
    }

    function calcpercgrassi(){
        return Math.round(
            (calcgrassi()*100/metabo)
            *100)/100
    }
    
    function calcpercoprote(){
        return Math.round(
            (calcprote()*100/metabo)
            *100)/100
    }

    function calcperccarbo(){
        return Math.round(
            (calccarb()*100/metabo)
            *100)/100
    }

    useEffect(() => {
        const mb=metabolismo(anagselezionata.sesso, calculateage(anagselezionata.datanascita, data),pesoideale(altezza,anagselezionata.sesso))
        setmetabo(mb)
    })

    return (
        <div className="row sezione">

        <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Macronutrienti</th>
                    <th>Quantità</th>
                    <th>%</th>
                    <th>Ripartizione FCG Obiettivo Peso ({metabo})</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                    Grassi
                    </td>
                    <td style={{textAlign:'right'}}>
                    <InputText
            bootstrapsize={"col-md-4"}
            disabled={true}
            type={"number"}
            value={grassiqta()}
             />
                    
                    </td>
                    <td style={{textAlign:'right'}}>
                        {calcpercgrassi()}
                    </td>
                    <td style={{textAlign:'right'}}>
                        {calcgrassi()}
                    </td>
                </tr>
                <tr>
                    <td>
                        Proteine
                        </td>
                    <td style={{textAlign:'right'}}>
                    <InputText
            
            bootstrapsize={"col-md-4"}
            name={"proteine"}
            onChange={(e) => setprote(e.target.value)}
            placeholder={"Proteine"}
            type={"number"}
            value={prote}
            step={.01}
             />
                    </td>
                    <td style={{textAlign:'right'}}>
{calcpercoprote()}
                    </td>
                    <td style={{textAlign:'right'}}>
                    {calcprote()}
                    </td>
                </tr>
                <tr>
                    <td>
                        Carboidrati
                        </td>
                    <td style={{textAlign:'right'}}>
                    <InputText
            bootstrapsize={"col-md-4"}
            name={"carboidrati"}
            onChange={(e) => setcarbo(e.target.value)}
            placeholder={"Carboidrati"}
            type={"number"}
            value={carbo}
            step={.01}
             />
                    </td>
                    <td style={{textAlign:'right'}}>
                    {calcperccarbo()}
                    </td>
                    <td style={{textAlign:'right'}}>
                    {calccarb()}
                    </td>
                </tr>
            
            
             </tbody>
             </table>
        </div>
    )
}