import axios from 'axios';
import serverInfo from '../util/globals';

export async function salvadatiEsercizio(email,idpalestra,idschedaesercizio, numsettimana, serie, recupero, tut, ripetizioni1, ripetizioni2, ripetizioni3, peso1, peso2, peso3) {
    const formData = new FormData();
    formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('palestra',idpalestra);
    formData.append('numsettimana',numsettimana);
    formData.append('serie', serie?serie:'');
    formData.append('recupero', recupero?recupero:0);
    formData.append('tut', tut?tut:'');
    formData.append('ripetizioni', ripetizioni1?ripetizioni1:'');
    formData.append('peso', peso1?peso1:'');
    formData.append('peso2', peso2?peso2:'');
    formData.append('ripetizioni2', ripetizioni2?ripetizioni2:0);
    formData.append('peso3', peso3?peso3:'');
    formData.append('ripetizioni3', ripetizioni3?ripetizioni3:0);
      const response = await axios.post(
        serverInfo.namesec+'/scheda/updSchedaEsercizioDett/'+idschedaesercizio,
        formData,
        {
          withCredentials: true,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
          },
        },
      );
      return response.data;
    }

    export async function getDatiEsercizio(email,idpalestra,idsesercizio) {
      const formData = new FormData();
      formData.append('website', global.config.domain);
      formData.append('email',email);
      formData.append('palestra',idpalestra);
      formData.append('idsesercizio',idsesercizio);
        const response = await axios.post(
          serverInfo.namesec+'/scheda/getDatiEsercizio/'+idsesercizio,
          formData,
          {
            withCredentials: true,
            headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Headers': 'Content-Type, Authorization',
              'Access-Control-Allow-Credentials': 'true',
              'Authorization' :'Bearer '+window.$t
            },
          },
        );
        return response.data;
      }

      
      export async function copiaDatiEsercizioNextSett(email,idpalestra,idschedaesercizio, numsettimana, serie, recupero, tut, ripetizioni1, ripetizioni2, ripetizioni3, peso1, peso2, peso3) {
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('palestra',idpalestra);
        formData.append('numsettimana',numsettimana);
        formData.append('serie', serie?serie:'');
        formData.append('recupero', recupero?recupero:0);
        formData.append('tut', tut?tut:'');
        formData.append('ripetizioni', ripetizioni1?ripetizioni1:'');
        formData.append('peso', peso1?peso1:'');
        formData.append('peso2', peso2?peso2:'');
        formData.append('ripetizioni2', ripetizioni2?ripetizioni2:0);
        formData.append('peso3', peso3?peso3:'');
        formData.append('ripetizioni3', ripetizioni3?ripetizioni3:0);
          const response = await axios.post(
            serverInfo.namesec+'/scheda/copiaDatiEsercizioNextSett/'+idschedaesercizio,
            formData,
            {
              withCredentials: true,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization' :'Bearer '+window.$t
              },
            },
          );
          return response.data;
        }