import React, { useEffect, useState } from "react";
import {  getscheda, getultimascheda, salvaschedadieta } from "../../DAO/schededietaDAO";
import { useSelector } from "react-redux";
import InputText from "../../util/UI/InputText";
import Text from "../../util/UI/Text";
import DettGiornoSchedadieta from "./DettGiornoSchedadieta";
import { getlistaalimenti } from "../../DAO/alimentiDAO";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AnnotatioAlert from "../../util/UI/AnnotatioAlert";
import NuovaSchedadieta from "./NuovaSchedadieta";
import { Button } from "react-bootstrap";
import serverInfo from "../../util/globals";

let timeout=0;
let totgiorno =null;
export default function DettaglioSchedadieta(props){
    const [modificato, setmodificato] = useState(false)

    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const anagrafica = useSelector(state => state.gestoreUtente.anagselezionata);

    const [expanded, setExpanded] = useState(false);
	
	const handleChange =
    (idcorso, panel, isExpanded) => {
	  setExpanded(isExpanded ? idcorso : false);
    };

    const [scheda, setscheda]=useState()
    const [data, setdata]=useState('')
    const [giorni, setgiorni]=useState([])

    const [giornisingoli, setgiornisingoli]=useState([])
    

    const [listaalimentiselect, setlistaalimentiselect] = useState([])

    let totkcalpasto = 0
    const [shownuovascheda, setshownuovascheda]=useState(false)
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())

    function getsommekcal(giorni, ritornarequalcosa){
        console.log(giorni)
        let sommakcal=0;
        let sommaprote=0;
        let sommacarbo=0;
        let sommazucc=0;
        if (giorni){
            if(Array.isArray(giorni)){
                const uniquegg = [...new Set(giorni.map(item => item.giorno))];
                let mappa=new Map();
                for (var it = uniquegg.values(), val= null; val=it.next().value; ) {
                    let sommagiornokcal=0;
                    let sommagiornoprote=0;
                    let sommagiornocarbo=0;
                    let sommagiornozucc=0;
                    giorni.map(giornotemp => {
                        if(giornotemp.giorno===val){
                            sommagiornokcal+=parseFloat(giornotemp.alimenti?giornotemp.alimenti.reduce((accumulator, alim)=> accumulator+(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.kcal),0):0).toFixed(2)
                            sommagiornoprote+=parseFloat(giornotemp.alimenti?giornotemp.alimenti.reduce((accumulator, alim)=> accumulator+(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.proteine),0):0).toFixed(2)
                            sommagiornocarbo+=parseFloat(giornotemp.alimenti?giornotemp.alimenti.reduce((accumulator, alim)=> accumulator+(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.carboidrati),0):0).toFixed(2)
                            sommagiornozucc+=parseFloat(giornotemp.alimenti?giornotemp.alimenti.reduce((accumulator, alim)=> accumulator+(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.saturi),0):0).toFixed(2)
                        }
                    }
                    )
                    mappa.set(val,{kcal:sommagiornokcal, prote: sommagiornoprote, carbo:sommagiornocarbo, zucc:sommagiornozucc});
                }
                //setgiornisingoli(...mappa)
                //calcola tot
                giorni.map( giorno=> {
                    sommakcal+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.kcal):0),0):0).toFixed(2)
                    sommaprote+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.proteine):0),0):0).toFixed(2)
                    sommacarbo+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.carboidrati):0),0):0).toFixed(2)
                    sommazucc+=parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.saturi):0),0):0).toFixed(2)
                })
            }
            else{
                sommakcal=parseFloat(giorni.alimenti?giorni.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.kcal):0),0):0).toFixed(2)
                sommaprote=parseFloat(giorni.alimenti?giorni.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.proteine):0),0):0).toFixed(2)
                sommacarbo=parseFloat(giorni.alimenti?giorni.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.carboidrati):0),0):0).toFixed(2)
                sommazucc=parseFloat(giorni.alimenti?giorni.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.saturi):0),0):0).toFixed(2)

            }
        }
        console.log(giorni)
        return {kcal:sommakcal, prote:sommaprote, carbo:sommacarbo, zucc:sommazucc}
    }

    function addpasto(ggtoadd){
        //console.log(giorni)
        let giornotemp;
        let rows = [...giorni];
        
        giornotemp=giorni.filter(gg => gg.giorno===ggtoadd)[0]
        giornotemp={
            giorno:ggtoadd,
            pasto:'',
            alimenti:[]
        };
        rows.push(giornotemp)
        
        if (rows&&Array.isArray(rows)&&rows.length>0){
            setgiorni(rows)
        }
    }

    function addriga(){
        //console.log(giorni)
        let giornotemp;
        let rows = [...giorni];
        
            const ggs = giorni.map(object => {
                return object.giorno;
            });
            console.log(ggs)
            const max = ggs&&Array.isArray(ggs)&&ggs.length>0?Math.max(...ggs):0;
            console.log(max)
            giornotemp={
                giorno:max?max+1:1,
                pasto:'',
                alimenti:[]
            };
            
            rows.push(giornotemp)
        
        
        if (rows&&Array.isArray(rows)&&rows.length>0){
            setgiorni(rows)
            const uniquegg = [...new Set(rows.map(item => (+item.giorno)))];
            console.log(uniquegg)
            setgiornisingoli(uniquegg.sort())
        }
        //setkeytorefresh(new Date().getTime())
    }

    const deletegiorno = (index)=>{
        const rows = [...giorni];
        rows.splice(index, 1);
        setgiorni(rows);
   }
 
   function handleChangeGiorni (index, evnt){
        const { name, value } = evnt.target;
        console.log(index)
        console.log(value)
        const rowsInput = [...giorni];
        rowsInput[index][name] = value;
        setgiorni(rowsInput);
        getsommekcal(rowsInput, false);
    }

    function handleChangeAlimenti(index, alimenti){
        console.log('handleChangeAlimenti '+index)
        const rowsInput = [...giorni];
        rowsInput[index]['alimenti'] = alimenti;
        setgiorni(rowsInput);
        getsommekcal(rowsInput, false)
    }

    function salvascheda(){
        async function salvasch(){
            const result = await salvaschedadieta(JSON.stringify(giorni), palestra.id,scheda.id, data, anagrafica.id)
            if (result.id>0){
                AnnotatioAlert({icon:'success'});
            }
            setscheda(result)
        }
        salvasch()
    }

    function caricalistaalimenti(){
        async function caricalista(){
            const result = await getlistaalimenti(palestra.id)

            //let listaalimentiselecttemp=[]
            //result.map(alim =>listaalimentiselecttemp.push({name:alim.nome, value:alim.id}) )
            setlistaalimentiselect(result)
        }
        caricalista()
    }

    function valoreordinamentopasto(pasto){
        if (pasto.toUpperCase()==='COLAZIONE'){
            return 1
        }
        if (pasto.toUpperCase()==='PRANZO'){
            return 3
        }
        if (pasto.toUpperCase()==='CENA'){
            return 5
        }
        if (pasto.toUpperCase()==='SPUNTINO 1'){
            return 2
        }
        if (pasto.toUpperCase()==='SPUNTINO 2'){
            return 4
        }

    }

    function cariacscheda(){
        async function carica(){ 
            let result;
            if(props.id){
                result=await getscheda(props.id)
            }
            else{
                result=await getultimascheda(anagrafica.id)
            }
            setscheda(result)
            setdata(result.data)
            console.log(result.pianoalimentare)
            if(result&&result.pianoalimentare){
                let resultgiorni=JSON.parse(result.pianoalimentare)
                resultgiorni=resultgiorni.sort(function(a, b) {
                    const apasto=a.pasto
                    const bpasto=b.pasto
                    return ((+a.giorno) - (+b.giorno)) || (valoreordinamentopasto(a.pasto)-valoreordinamentopasto(b.pasto))
                })
                if (resultgiorni&&Array.isArray(resultgiorni)&&resultgiorni.length>0){
                    setgiorni(resultgiorni)
                    let uniquegg = [...new Set(resultgiorni.map(item => (item.giorno)))];
                    //console.log(uniquegg)
                    //uniquegg=uniquegg.sort()
                    setgiornisingoli(uniquegg)
                    //console.log(uniquegg)
                }
                //console.log(resultgiorni)
                setshownuovascheda(false)
            }
            else{
                setshownuovascheda(true)
            }
        }
        carica()
    }

    function stamptotgiorno(gg){
        totgiorno=null
        if (gg&& (+gg>=0)){
                    let sommakcal=0;
                    let sommaprote=0;
                    let sommacarbo=0;
                    let sommazucc=0;
                    
                giorni.map( giorno=> {
                    if (giorno.giorno===gg){
                        console.log('sommakcal '+sommakcal)
                        sommakcal=Math.round((parseFloat(sommakcal)+parseFloat(parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.kcal):0),0):0).toFixed(2)))*100)/100
                        sommaprote=Math.round((parseFloat(sommaprote)+parseFloat(parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.proteine):0),0):0).toFixed(2)))*100)/100
                        sommacarbo=Math.round((parseFloat(sommacarbo)+parseFloat(parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.carboidrati):0),0):0).toFixed(2)))*100)/100
                        sommazucc=Math.round((parseFloat(sommazucc)+parseFloat(parseFloat(giorno.alimenti?giorno.alimenti.reduce((accumulator, alim)=> accumulator+(alim&&alim.alimentobo?(alim.quantita/alim.alimentobo.qtarif*alim.alimentobo.saturi):0),0):0).toFixed(2)))*100)/100
                    }
                })
                totgiorno={giorno: gg, kcal:sommakcal, prote:sommaprote, carbo:sommacarbo, zucc:sommazucc}
                console.log("totgiorno")
                console.log(totgiorno)
                //setkeytorefresh(new Date().getTime())
        }
        else{
            console.log('niente')
        }
    }

    useEffect(() => {
        cariacscheda()
        caricalistaalimenti()
    },[props.id])

    function stampadieta(){

		let windowName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString();
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", global.config.server+'/stampaultimascheda');
		
		form.setAttribute("target", windowName);
		
		var email = document.createElement("input"); 
		email.setAttribute("type", "hidden");
		email.setAttribute("name", "idanag");
		email.setAttribute("value", anagrafica.id);
		form.appendChild(email);

		document.body.appendChild(form);
		
		window.open('', windowName,'_blank');
		
		form.submit();

    }

    useEffect(() => {
        if (!modificato) {
            setmodificato(true)
         } else {
            clearTimeout(timeout); 
            timeout = setTimeout(() => {
                salvascheda()
                
            },1000) 
        }
        
    },[giorni])
    return(
        <div key={keytorefresh} className="container-fluid sezione">
            <h4>Dettaglio Scheda Dieta</h4>
           
            {scheda&&
            <div>
            
            <div className="row">
                <Text bootstrapsize={"col-md-3"} id={"data"} label={"Id Scheda"} value={scheda.id} />
                <Text bootstrapsize={"col-md-4"} id={"data"} label={"Anagrafica"} value={anagrafica.nome + " " +anagrafica.cognome} />
                <Button onClick={stampadieta}>Stampa</Button>
            </div>
            <div className="row">
                <InputText bootstrapsize={'col-md-4'}
                id={'data'} label={'Data'} name={'data'} 
                placeholder={'Data'} onChange={(e) => setdata(e.target.value)} type={'date'}
                value={data} 
                 />
            </div>
            
            <button className="btn btn-primary" onClick={() =>addriga()}>Aggiungi Giorno</button>
           
            {giornisingoli.map(gg =>(
                
                <Accordion>
                    {stamptotgiorno(gg)}
                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={gg+"bh-content"}
                                                        id={gg+"bh-header"}>
                                                            <Typography sx={{ width: '10%', flexShrink: 0, }}>
                                                            Giorno {gg}
                                                            </Typography>
                                                            <Typography sx={{ width: 'auto', flexShrink: 0, }}>
                                                            <Text label={totgiorno&&totgiorno.giorno?"kcal "+totgiorno.kcal+", proteine: "+totgiorno.prote+", carbo: "+totgiorno.carbo+", zucc:"+totgiorno.zucc:''} />
                                                            </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    

            {giorni&&Array.isArray(giorni)&&giorni.map((giornoscheda, index) => (

                 (+giornoscheda.giorno)===(+gg)&&
                    <Accordion expanded={expanded === index} onChange={(event,isExpanded) => handleChange(index, event, isExpanded)}>
                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={index+"bh-content"}
                                                    id={index+"bh-header"}>
                                                        <Typography sx={{ width: '10%', flexShrink: 0, }}>
                                                        {giornoscheda.pasto}
                                                        </Typography>
                                                        <Typography sx={{ width: '80%', flexShrink: 0, }}>
                                                            <div className="row">
                                                        <Text label="kcal" bootstrapsize="col-md-2" value={getsommekcal(giornoscheda, true).kcal} /> 
                                                        <Text label="prote" bootstrapsize="col-md-2" value={getsommekcal(giornoscheda, true).prote} /> 
                                                        <Text label="carbo" bootstrapsize="col-md-2" value={getsommekcal(giornoscheda, true).carbo} />
                                                        <Text label="zuccheri" bootstrapsize="col-md-2" value={getsommekcal(giornoscheda, true).zucc} />
                                                        </div>
                                                        </Typography>
                                                        
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                <DettGiornoSchedadieta key={index} giornoscheda={giornoscheda} 
                                    listaalimenti={listaalimentiselect}
                                    addriga={addriga}
                                    addpasto={addpasto}
                                    handleChangeGiorni={handleChangeGiorni} indexgiorno={index} 
                                    deletegiorno={deletegiorno}
                                    handleChangeAlimenti={handleChangeAlimenti} 
                                    totkcalpasto={totkcalpasto}/>
                                </AccordionDetails>
                                
                </Accordion>
                
            ))}

            </AccordionDetails>
            </Accordion>
            ))
        }
            
          
        </div>}
        {!scheda&&
        <div>
            Nessuna scheda trovata</div>
        }
        {shownuovascheda&&<NuovaSchedadieta setkeytorefresh={setkeytorefresh} />}
        </div>
    );
}