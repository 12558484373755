import React from "react";
import { useState } from "react";
import './abbonamenti.css'
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { caricaabbonamentipalestra } from "../DAO/palestraDAO";
export default function AbbonamentiPalestra(){

  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
  const palestrasocio = useSelector(state => state.gestoreUtente.palestraassociata);
  const token = useSelector(state => state.gestoreUtente.token);

  const [abbonamenti, setabbonamenti] = useState([])

    useEffect(() => {
      async function caricaabb(){
        const result=await caricaabbonamentipalestra(emailloggato, palestrasocio.palestra.id, token);
        setabbonamenti(result)
      }
      caricaabb();
    },[emailloggato, palestrasocio])

    return (
      <div className="subscription-history">
      <h2>Subscription History</h2>
  
  {abbonamenti&&Array.isArray(abbonamenti)&&abbonamenti.length>0&&
    abbonamenti.map((abb, index) => (
      <div className={"subscription-entry "+(index===0?"active-subscription":"")}>
        <p><strong>Subscription:</strong></p>
        <p><strong>Start Date:</strong> {abb.validoDa}</p>
        <p><strong>End Date:</strong> {abb.validoA}</p>
        <p><strong>Plan:</strong> {abb.listino}</p>
        <p><strong>Price Paid:</strong> {abb.prezzo}</p>
      </div>
    )
    )
}
{
  !abbonamenti||!Array.isArray(abbonamenti)||abbonamenti.length===0&&
  <p>Nessun abbonamento trovato</p>
}
  
    </div>




    );
}