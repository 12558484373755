import React, { useState } from 'react';
import axios from 'axios';
import serverInfo from '../../util/globals';
import { useSelector } from 'react-redux';

function ImportEsami() {
    const token = useSelector(state => state.gestoreUtente.token);
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);

  const [file, setFile] = useState(null);
  const [upldisabled, setupldisabled] = useState(false);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (!file){
        return
    }
    setupldisabled(true)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('palestra', palestra.id);
    formData.append('email', emailloggato);
    axios
      .post(serverInfo.namesec+'/cc/import-csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+token
        },
      })
      .then((response) => {
        // Gestisci la risposta dal server dopo l'upload
        console.log(response.data);
        alert(response.data.message)
        setupldisabled(false)
      })
      .catch((error) => {
        // Gestisci eventuali errori
        console.error(error);
      });
  };

  return (
    <div>
      <h1>Upload CSV</h1>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <button onClick={handleUpload} disabled={upldisabled}>Upload</button>
    </div>
  );
}

export default ImportEsami;
