import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import UltimeIscrizioni from './riquadri/UltimeIscrizioni';
import PalestreAssociateSelect from './riquadri/PalestreAssociateSelect';
import RiquadroRicAnagrafiche from './riquadri/RiquadroRicAnagrafiche';
import RiquadroRisRicAnagrafiche from './riquadri/RiquadroRisRicAnagrafiche';
import RiquadroDettAnagrafica from './riquadri/RiquadroDettAnagrafica';
import AbbonamentiUtente from './riquadri/AbbonamentiUtente';
import Prenotazioni from './prenotazioni/Prenotazioni';
import Ingressi from './ingressi/Ingressi';
import Messaggi from './messaggi/Messaggi';
import NuovaAnagrafica from './anagrafica/NuovaAnagrafica';
import Dashboard from './report/Dashboard';
import RicercaSchedaSA from './schedasalaattrezzi/RicercaSchedaSA';
import DettaglioScheda from './schedasalaattrezzi/DettaglioScheda';
import RicercaCassa from './cassa/RicercaCassa';
import NuovoMovimentoCassa from './cassa/NuovoMovimentoCassa';
import DettMovimentoCassa from './cassa/DettMovimentoCassa';
import AccessiGiornalieri from './accessi/AccessiGiornalieri';
import Calendari from './calendario/Calendari';
import Archivi from './archivi/Archivi';
import TipologieCorso from './archivi/tipologiaCorso/TipologieCorso';
import ListaCorsi from './archivi/corsi/ListaCorsi';
import TipologieAbbonamentoInput from "./archivi/tipologiaAbbonamento/TipologieAbbonamentoInput";
import PeriodiInput from "./archivi/periodi/PeriodiInput";
import ListiniInput from "./archivi/listino/ListiniInput";
import ScontiInput from "./archivi/sconti/ScontiInput";
import Collaboratori from './utenti/Collaboratori';
import RiquadroRicAnagVeloce from './riquadri/RiquadroRicercaAnagVeloce';
import AccessiGiornalieriTornello1 from './accessi/AccessiGiornalieriTornello1';
import ControlloTornello1 from './accessi/ControlloTornello1';
import RiquadroLogo from './riquadri/RiquadroLogo';
import Palestra from './palestra/Palestra';

import CalendarioAdmin from './calendario/CalendarioAdmin';
import Ordini from './ordini/Ordini';
import ListaSchedeCompCorp from './schedasalaattrezzi/composizionecorporea/ListaSchedeCompCorp';
import ListaSchedeDiarioAlimentare from './schedasalaattrezzi/diarioalimentare/ListaSchedeDiarioAlimentare';
import RicercaSchedaCC from './schedasalaattrezzi/RicercaSchedaCC';
import GestioneSchede from './GestioneSchede';
import { Provider } from 'react-redux';
import store from './STORE/store'
import AppRoute from './AppRoute';
import LoadMainThings from './util/LoadMainThings';
import Uploadtesserati from './tesserati/Uploadtesserati';
import Intestazione from './toolbar/Intestazione';
import InsPalestra from './palestra/InsPalestra';

var appadminannotatio=document.getElementById("appadminannotatio");
var first_name=''
var last_name=''

if (appadminannotatio){
  var email=appadminannotatio.getAttribute("email");
  var idpalestra=0
  const queryParameters = new URLSearchParams(window.location.search)
  if (!email){
    email = queryParameters.get("email")
    idpalestra = queryParameters.get("idpalestra")
  }
  if (!email){
    email = localStorage.getItem("email")
    idpalestra = localStorage.getItem("palselezionata")
  }
  
  if (email){
    localStorage.setItem('email',email);
  }
  const nome=appadminannotatio.getAttribute("first_name");
  const cognome=appadminannotatio.getAttribute("last_name");
  const telefono=appadminannotatio.getAttribute("telefono");
  
  const key=appadminannotatio.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=appadminannotatio.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  var tkn=appadminannotatio.getAttribute("t");
  if (!tkn){
    tkn = queryParameters.get("token")
  }
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <AppRoute showtoolbar={true} showheader={false} email={email} 
      nome={nome} cognome={cognome} telefono={telefono} idpalestraselezionata={idpalestra}
      t={tkn} />
    </Provider>,
  appadminannotatio
  )
}

if (document.getElementById("wp-admin-bar-user-actions")){
  document.getElementById("wp-admin-bar-user-actions").remove()
}
if (document.getElementById("menucliente")){
  document.getElementById("menucliente").style.display="block"
}
//se ha selezionato una palestra
if (global.localstorage.anagraficaAssociata
    &&global.localstorage.palestraAnagraficaAassociata){

      if (document.getElementById("barranavigcliente")){
        document.getElementById("barranavigcliente").style.display="block"
      }     

}

//se ha selezionato un'anagrafica
if (global.localstorage.palestraSelezionata){
  if (document.getElementById("barranavigadmin")){
    document.getElementById("barranavigadmin").style.display="block"
  }
  if (document.getElementById("menuadmin")){
    document.getElementById("menuadmin").style.display = "block";
  }
  if (document.getElementById("menucliente")){
    document.getElementById("menucliente").remove()
  }

   if (document.getElementById("wp-admin-bar-new-content")){
     document.getElementById("wp-admin-bar-new-content").remove()
   }

  if (document.getElementById("riquadroiscrizioni")){
    document.getElementById("riquadroiscrizioni").remove()
  }
  if (document.getElementById("riquadrocalendari")){
    document.getElementById("riquadrocalendari").remove()
  }
  if (document.getElementById("listacalendari")){
    document.getElementById("listacalendari").remove()
  }
  
  if (document.getElementById("riquadrocassa")){
    document.getElementById("riquadrocassa").remove()
  }
  if (document.getElementById("riquadroreport")){
    document.getElementById("riquadroreport").remove()
  }
  if (document.getElementById("riquadroschede")){
    document.getElementById("riquadroschede").remove()
  }
  if (document.getElementById("riquadroaccessi")){
    document.getElementById("riquadroaccessi").remove()
  }
  if (document.getElementById("riquadroultimeiscrizioni")){
    document.getElementById("riquadroultimeiscrizioni").remove()
  }
}

var appadminschede=document.getElementById("appadminschede");

if (appadminschede){
  const email=appadminschede.getAttribute("email");
  const tkn=appadminschede.getAttribute("t");
  window.$t=tkn;
  ReactDOM.render(
    <GestioneSchede email={email} />,
    appadminschede
  )
}

var emailSpecificata=''

var ultimeiscrizioni=document.getElementById("ultimeiscrizioni");
if (ultimeiscrizioni){
  const email=ultimeiscrizioni.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=ultimeiscrizioni.getAttribute("key");
  localStorage.setItem('key',key);
  const tkn=ultimeiscrizioni.getAttribute("t");
  window.$t=tkn;
  ReactDOM.render(
        <UltimeIscrizioni email={email} />
      ,
      ultimeiscrizioni
    );
}

var archivi=document.getElementById("archivi");
if (archivi){
  const email=archivi.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=archivi.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=archivi.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=archivi.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
        <Archivi email={email} link={linkannotatio} />
      ,
      archivi
    );
}

var accessi1=document.getElementById("accessi1");
if (accessi1){
  const email=accessi1.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=accessi1.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=accessi1.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=accessi1.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
        <AccessiGiornalieriTornello1 email={email} link={linkannotatio} />
      ,
      accessi1
    );
}

var controlloaccessi1=document.getElementById("controlloaccessi1");
if (controlloaccessi1){
  const email=controlloaccessi1.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=controlloaccessi1.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=controlloaccessi1.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=controlloaccessi1.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
        <ControlloTornello1 email={email} link={linkannotatio} />
      ,
      controlloaccessi1
    );
}


/************+ */
//LISTA PALESTRE ASSOCIATE
/************ */
var palestraassociateee=document.getElementById("palestraassociateee");
if (palestraassociateee){
  const email=palestraassociateee.getAttribute("email");
  if (email){
   localStorage.setItem('email',email);
  }

  const key=palestraassociateee.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=palestraassociateee.getAttribute("linkannotatio");
  const linknuovapal=palestraassociateee.getAttribute("linknewpal");
  const showlistpalestre=palestraassociateee.getAttribute("showlistpalestre");
  const tkn=palestraassociateee.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
      <Provider store={store}>
        <LoadMainThings email={email} />
        <PalestreAssociateSelect email={email} 
        link={linkannotatio} showlistpalestre={showlistpalestre?showlistpalestre:false}
        linknewpal={linknuovapal} />
      </Provider>
      ,
      palestraassociateee
    );
}

/************+ */
//INSERIMENTO NUOVA PALESTRA
/************ */
var inspalestra=document.getElementById("inspalestra");
if (inspalestra){
  const email=inspalestra.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }

  const key=inspalestra.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=inspalestra.getAttribute("linkannotatio");

  const nome=inspalestra.getAttribute("first_name");
  const cognome=inspalestra.getAttribute("last_name");
  const telefono=inspalestra.getAttribute("telefono");

  const tkn=inspalestra.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
      <Provider store={store}>
        <LoadMainThings email={email} nome={nome} cognome={cognome} telefono={telefono}/>
        <InsPalestra email={email} link={linkannotatio} 
        nome={nome} cognome={cognome} telefono={telefono}/>
      </Provider>
      ,
      inspalestra
    );
}

var ricAnag=document.getElementById("ricAnag");
if (ricAnag){
  const email=ricAnag.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=ricAnag.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=ricAnag.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=ricAnag.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
        <RiquadroRicAnagrafiche email={email} link={linkannotatio} />
      ,
      ricAnag
    );
}

var risRicAnag=document.getElementById("risRicAnag");
if (risRicAnag){
  const email=risRicAnag.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=risRicAnag.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=risRicAnag.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=risRicAnag.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <RiquadroRisRicAnagrafiche email={email} link={linkannotatio}/>
      ,
      risRicAnag
    );
}

var dettAnag=document.getElementById("dettAnag");
if (dettAnag){
  const email=dettAnag.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=dettAnag.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=dettAnag.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=dettAnag.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <RiquadroDettAnagrafica email={email} link={linkannotatio}/>
      ,
      dettAnag
    );
}

var abbonamenti=document.getElementById("abbonamenti");
if (abbonamenti){
  const email=abbonamenti.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=abbonamenti.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=abbonamenti.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=abbonamenti.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <AbbonamentiUtente email={email} link={linkannotatio}/>
      ,
      abbonamenti
    );
}

var prenotazioni=document.getElementById("prenotazioni");
if (prenotazioni){
  const email=prenotazioni.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=prenotazioni.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=prenotazioni.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=prenotazioni.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <Prenotazioni email={email} link={linkannotatio}/>
      ,
      prenotazioni
    );
}

var ingressiutente=document.getElementById("ingressiutente");
if (ingressiutente){
  const email=ingressiutente.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=ingressiutente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=ingressiutente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=ingressiutente.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <Ingressi email={email} link={linkannotatio}/>
      ,
      ingressiutente
    );
}

var messaggi=document.getElementById("messaggiutente");
if (messaggi){
  const email=messaggi.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=messaggi.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=messaggi.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=messaggi.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <Messaggi email={email} link={linkannotatio}/>
      ,
      messaggi
    );
}

var nuovaanag=document.getElementById("nuovaanag");
if (nuovaanag){
  const email=nuovaanag.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=nuovaanag.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=nuovaanag.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=nuovaanag.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <NuovaAnagrafica email={email} link={linkannotatio}/>
      ,
      nuovaanag
    );
}


var calendario=document.getElementById("calendario");
if (calendario){
  const email=calendario.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=calendario.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=calendario.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=calendario.getAttribute("t");
  window.$t=tkn;

  ReactDOM.render(
        <CalendarioAdmin  email={email} link={linkannotatio}/>
      ,
      calendario
    );
}

var calendari=document.getElementById("calendari");
if (calendari){
  const email=calendari.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=calendari.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=calendari.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=calendari.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <Calendari email={email} link={linkannotatio}/>
      ,
      calendari
    );
}

var dashboard=document.getElementById("dashboard");
if (dashboard){
  const email=dashboard.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=dashboard.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=dashboard.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=dashboard.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <Dashboard  email={email} link={linkannotatio}/>
      ,
      dashboard
    );
}

var ricercaSchedaSA=document.getElementById("ricercaSchedaSA");
if (ricercaSchedaSA){
  const email=ricercaSchedaSA.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=ricercaSchedaSA.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=ricercaSchedaSA.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=ricercaSchedaSA.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <RicercaSchedaSA  email={email} link={linkannotatio}/>
      ,
      ricercaSchedaSA
    );
}

var ricercaSchedaCC=document.getElementById("ricercaSchedaCC");
if (ricercaSchedaCC){
  const email=ricercaSchedaCC.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=ricercaSchedaCC.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=ricercaSchedaCC.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=ricercaSchedaCC.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <RicercaSchedaCC  email={email} link={linkannotatio}/>
      ,
      ricercaSchedaCC
    );
}

var dettSchedaSA=document.getElementById("dettSchedaSA");
if (dettSchedaSA){
  const email=dettSchedaSA.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=dettSchedaSA.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=dettSchedaSA.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=dettSchedaSA.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <DettaglioScheda  email={email} link={linkannotatio}/>
      ,
      dettSchedaSA
    );
}

var ricCassa=document.getElementById("ricCassa");
if (ricCassa){
  const email=ricCassa.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=ricCassa.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=ricCassa.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=ricCassa.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <RicercaCassa  email={email} link={linkannotatio}/>
      ,
      ricCassa
    );
}

var newCassa=document.getElementById("newCassa");
if (newCassa){
  const email=newCassa.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=newCassa.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=newCassa.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=newCassa.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <NuovoMovimentoCassa  email={email} link={linkannotatio}/>
      ,
      newCassa
    );
}

var dettCassa=document.getElementById("dettCassa");
if (dettCassa){
  const email=dettCassa.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=dettCassa.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=dettCassa.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=dettCassa.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <DettMovimentoCassa  email={email} link={linkannotatio}/>
      ,
      dettCassa
    );
}

var accessiGiornalieri=document.getElementById("accessiGiornalieri");
if (accessiGiornalieri){
  const email=accessiGiornalieri.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=accessiGiornalieri.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=accessiGiornalieri.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=accessiGiornalieri.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <AccessiGiornalieri  email={email} link={linkannotatio}/>
      ,
      accessiGiornalieri
    );
}

var tiposervizi=document.getElementById("tiposervizi");
if (tiposervizi){
  const email=tiposervizi.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=tiposervizi.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=tiposervizi.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=tiposervizi.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <TipologieCorso  email={email} link={linkannotatio}/>
        </Provider>
      ,
      tiposervizi
    );
}

var servizi=document.getElementById("servizi");
if (servizi){
  const email=servizi.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=servizi.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=servizi.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const noncino=servizi.getAttribute("noncino");
  console.log(noncino)

  const tkn=servizi.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <ListaCorsi noncino={noncino} email={email} link={linkannotatio}/>
        </Provider>
      ,
      servizi
    );
}

var tipoabb=document.getElementById("tipoabb");
if (tipoabb){
  const email=tipoabb.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=tipoabb.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=tipoabb.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=tipoabb.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <TipologieAbbonamentoInput email={email} link={linkannotatio}/>
      ,
      tipoabb
    );
}

var periodi=document.getElementById("periodi");
if (periodi){
  const email=periodi.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=periodi.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=periodi.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=periodi.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <PeriodiInput email={email} link={linkannotatio}/>
      ,
      periodi
    );
}

var listini=document.getElementById("listini");
if (listini){
  const email=listini.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=listini.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=listini.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=listini.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <ListiniInput email={email} link={linkannotatio}/>
      ,
      listini
    );
}

var sconti=document.getElementById("sconti");
if (sconti){
  const email=sconti.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=sconti.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=sconti.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=sconti.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <ScontiInput email={email} link={linkannotatio}/>
      ,
      sconti
    );
}

var collaboratori=document.getElementById("collaboratori");
if (collaboratori){
  const email=collaboratori.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=collaboratori.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=collaboratori.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=collaboratori.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <Collaboratori email={email} link={linkannotatio}/>
      ,
      collaboratori
    );
}

var ricAnagGenerica=document.getElementById("ricAnagGenerica");
if (ricAnagGenerica){
  const email=ricAnagGenerica.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=ricAnagGenerica.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=ricAnagGenerica.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=ricAnagGenerica.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <RiquadroRicAnagVeloce email={email} link={linkannotatio}/>
      ,
      ricAnagGenerica
    );
}

var ordini=document.getElementById("ordini");
if (ordini){
  const email=ordini.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=ordini.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=ordini.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=ordini.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <Provider store={store}>
          <LoadMainThings email={email} />
          <Ordini email={email} link={linkannotatio}/>
        </Provider>
      ,
      ordini
    );
}

var logo=document.getElementById("logo");
if (logo){

  ReactDOM.render(
        <RiquadroLogo />
      ,
      logo
    );
}

var intestazione=document.getElementById("intestazione");
if (intestazione){
  const email=intestazione.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email
  const tkn=intestazione.getAttribute("t");
  window.$t=tkn;
  ReactDOM.render(
    <Provider store={store}>
        <LoadMainThings email={email} />
        <Intestazione />
        </Provider>
      ,
      intestazione
    );
}

var dettPalestra=document.getElementById("dettPalestra");
if (dettPalestra){
  const email=dettPalestra.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }
  emailSpecificata=email

  const key=dettPalestra.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=dettPalestra.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=dettPalestra.getAttribute("t");
  if (tkn){
    window.$t=tkn;
  }

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <Palestra email={email} link={linkannotatio}/>
        </Provider>
      ,
      dettPalestra
    );
}

var schedeCompCorp=document.getElementById("schedeCompCorp");
if (schedeCompCorp){
  const email=schedeCompCorp.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }  emailSpecificata=email

  const key=schedeCompCorp.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=schedeCompCorp.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=schedeCompCorp.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <ListaSchedeCompCorp email={email} link={linkannotatio}/>
      ,
      schedeCompCorp
    );
}

var schedeDiarioAliment=document.getElementById("schedeDiarioAliment");
if (schedeDiarioAliment){
  const email=schedeDiarioAliment.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }  emailSpecificata=email

  const key=schedeDiarioAliment.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=schedeDiarioAliment.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=schedeDiarioAliment.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
        <ListaSchedeDiarioAlimentare email={email} link={linkannotatio}/>
      ,
      schedeDiarioAliment
    );
}

var schedeCompCorp=document.getElementById("uploadtesserati");
if (schedeCompCorp){
  const email=schedeCompCorp.getAttribute("email");
  if (email){
    localStorage.setItem('email',email);
   }  emailSpecificata=email

  const tkn=schedeCompCorp.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <Uploadtesserati email={email} />
        </Provider>
      ,
      schedeCompCorp
    );
}

var logoutButtons=document.querySelectorAll('.logoutlink')
    if (logoutButtons){
      for (var i=0; i<logoutButtons.length; i++){
        logoutButtons[i].addEventListener('click', () => {
          localStorage.clear()
          if (window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage('clean');
           }
        })
      }
    }

    logoutButtons=document.querySelectorAll('.login_logout_class')
    if (logoutButtons){
      for (var i=0; i<logoutButtons.length; i++){
        logoutButtons[i].addEventListener('click', () => {
          localStorage.clear()
          if (window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage('clean');
           }
        })
      }
    }

    logoutButtons=document.querySelectorAll('a[href*="logout"]')
    if (logoutButtons){
      for (var i=0; i<logoutButtons.length; i++){
        logoutButtons[i].addEventListener('click', () => {
          localStorage.clear()
          if (window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage('clean');
           }
        })
      }
    }

    /*********************
     * !!! Integrazione tra webview e wordpress !!!
     ********************/

    // var dataToPass=''
    //  if (localStorage.getItem('email')){
    //   dataToPass=localStorage.getItem('email')
    //  }
    //  dataToPass+="___"
    //   if (localStorage.getItem('code')||localStorage.getItem('utente')){
    //       dataToPass+=localStorage.getItem('code')?localStorage.getItem('code'):'SOCIO-'+localStorage.getItem('utente')
    //   }

    //  if (window.ReactNativeWebView){
    //   window.ReactNativeWebView.postMessage(dataToPass);
    //  }

/*************************
 * SERVICE WORKER
 ************************/

// var publicSigningKey;

// if ("serviceWorker" in navigator && window.location.hostname.indexOf('localhost')<0) {
//   try {
// 	  checkSubscription();
//     init();
//   } catch (e) {
//     console.error('error init(): ' + e);
//   }

//   async function subscribe() {
//     const registration = await navigator.serviceWorker.ready;
//     const subscription = await registration.pushManager.subscribe({
//       userVisibleOnly: true,
//       applicationServerKey: publicSigningKey,
//     });
//     console.info(`Subscribed to Push Service: ${subscription.endpoint}`);
//     mostraDisabilitazionePush();
//     await fetch(global.config.server+"/subscribe", {
//       method: 'POST',
//       body: JSON.stringify(subscription),
//       headers: {
//         "content-type": "application/json",
//         "email":localStorage.getItem('email')
//       }
//     });
//   }

//   async function unsubscribe() {
//     const registration = await navigator.serviceWorker.ready;
//     const subscription = await registration.pushManager.getSubscription();
//     if (subscription) {
//       const successful = await subscription.unsubscribe();
//       if (successful) {
//         mostraAbilitazionePush();
//         console.info('Unsubscription successful');
  
//         await fetch(global.config.server+"/unsubscribe", {
//           method: 'POST',
//           body: JSON.stringify({endpoint: subscription.endpoint}),
//           headers: {
//             "content-type": "application/json",
//             "email":localStorage.getItem('email')
//           }
//         });
  
//         console.info('Unsubscription info sent to the server');
//       }
//       else {
//         console.error('Unsubscription failed');
//       }
//     }
//   }

//   async function init() {
//     fetch(global.config.server+'/publicSigningKey')
//        .then(response => response.arrayBuffer())
//        .then(key =>{
//          console.log(key)
//         publicSigningKey = key
//        } )
//        .finally(() => console.info('Application Server Public Key fetched from the server'));
  
//     await navigator.serviceWorker.register("/sw.js", {
//       scope: "/"
//     });
  
//     await navigator.serviceWorker.ready;
//     console.info('Service Worker has been installed and is ready');

//   }

//   async function checkSubscription() {
//     const registration = await navigator.serviceWorker.ready;
//     const subscription = await registration.pushManager.getSubscription();
//     var subscribed = false;
//     if (subscription) 
//     {
  
//       const response = await fetch(global.config.server+"/isSubscribed", {
//         method: 'POST',
//         body: JSON.stringify({endpoint: subscription.endpoint}),
//         headers: {
//           "content-type": "application/json",
//           "email":localStorage.getItem('email')
//         }
//       });
//       subscribed = await response.json();
//     }
//       if (subscribed) {
//         console.log('GIA FATTO')
//         mostraDisabilitazionePush();
//       }
//       else{
//         mostraAbilitazionePush()
//       }
//       return subscribed;
    

//   }

//   function mostraAbilitazionePush(){
//     console.log('mostra abilitazione push')
//     var enableNotificationButtons=document.querySelectorAll('.enable-notifications')
//     if (enableNotificationButtons){
//       console.log('VAI')
//       enableNotificationButtons[0].style.display='inline-block';
//       nascondiDisabilitazionePush();
//     }
//     console.log('finito')
//   }

//   function nascondiAbilitazionePush(){
//     console.log('nascondi abilitazione push')
//     var enableNotificationButtons=document.querySelectorAll('.enable-notifications')
//     if (enableNotificationButtons){
//       console.log('VAI')
//       enableNotificationButtons[0].style.display='none';
//     }
//     console.log('finito')
//   }

// function mostraDisabilitazionePush(){
//   console.log('mostra disabilitazione push')
//     var disableNotificationButtons=document.querySelectorAll('.disable-notifications')
//     if (disableNotificationButtons){
//       console.log('VAI')
//       disableNotificationButtons[0].style.display='inline-block';
      
//       nascondiAbilitazionePush();
//     }
//     console.log('finito')
// }

// function nascondiDisabilitazionePush(){
//   console.log('nascondi disabilitazione push')
//     var disableNotificationButtons=document.querySelectorAll('.disable-notifications')
//     if (disableNotificationButtons){
//       console.log('VAI')
//       disableNotificationButtons[0].style.display='none';
//     }
//     console.log('finito')
// }

  

//     var enableNotificationButtons=document.querySelectorAll('.enable-notifications')
//     if (enableNotificationButtons){
//       enableNotificationButtons[0].addEventListener('click', () => {
//           subscribe().catch(e => {
//             if (Notification.permission === 'denied') {
//                   alert('Permission for notifications was denied');
//               } else {
//                 alert('error subscribe(): ' + e);
//               }   
//           })
//         }
//         )
//     }

// var disableNotificationButtons=document.querySelectorAll('.disable-notifications')
//     if (disableNotificationButtons){
//       disableNotificationButtons[0].addEventListener('click', () => {
//           unsubscribe().catch(e => {
//             if (Notification.permission === 'denied') {
//                   alert('Permission for notifications was denied');
//               } else {
//                 alert('error unsubscribe(): ' + e);
//               }   
//           })
//         }
//         )
//     }

// }
//}                
          //   function askForNotificationPermission(){
          //       Notification.requestPermission(function(result){
          //           console.log('User choice',result);
          //           if (result!=='granted'){
          //               console.log('No notification permission granted')
          //           }else{
          //             //configurePushSub();
          //             //displayConfirmNotification();
          //               console.log('GRANTED');
          //           }
          //       })
          //   }

          //   function displayConfirmNotification(){
          //     if ('serviceWorker' in navigator){
          //       var options={
          //         body:'You successfully subscribed to our notification service',
          //         icon:'/wp-content/uploads/2022/04/32x32.png',
          //         vibrate:[100,50,200],
          //         badge:'/wp-content/uploads/2022/04/annotatioBadge-1.png',
          //         tag:'confirm-notification',
          //         renotify:true
          //       }
          //       navigator.serviceWorker.ready
          //       .then(function(swreg){
          //         swreg.showNotification('Successfully subscribed', options)
          //       })
          //     }
             
          // }

          
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();