import {createSlice} from '@reduxjs/toolkit';

export const gestoreTipiProdottoSlice = createSlice({
  name: 'gestoreTipiProdotto',
  initialState: {
    tipi: [],
    tipo: {id:0},
    places:[]
  },
  reducers: {
    settipiprodotto: (state, tipi) => {
      console.log('settipi ' + tipi.payload);
      state.tipi = tipi.payload;
    },
    settipoprodotto: (state, tipo) => {
      //console.log('settipo ',tipo.payload);
      state.tipo = tipo.payload;
    },
    setplaces: (state, places) => {
      //console.log('setplaces ' + places.payload);
      state.places = places.payload;
    },
  },
});

export const {settipiprodotto, settipoprodotto, setplaces} = gestoreTipiProdottoSlice.actions;

export default gestoreTipiProdottoSlice.reducer;
