import React, { useEffect, useState } from "react";
import '../util/config'
import PalestraAssociataSelect from "./PalestraAssociataSelect";
import { useSelector } from "react-redux";

export default function PalestreAssociateSelect (props) {
  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
  const palestreassociate = useSelector(state => state.gestoreUtente.palestreassociate);

  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
  const [toogle, settoogle] = useState(true)

      return (
          <div key={keytorefresh}>
            {emailloggato&&palestreassociate&&Array.isArray(palestreassociate)&&palestreassociate.length>=0&&
             <div key={keytorefresh} className="container">
            <div className="row">
              <div className="col-md">
              <h3 style={{cursor:"pointer"}} onClick={() => settoogle(!toogle)}>Entra come gestore</h3>
              </div>
              <div className="col-md">
              {toogle&&<button className="button btn btn-success" onClick={() => window.location.href=props.linknewpal?props.linknewpal:'/annotatio-ins-pal/'}>Nuova attivita' commerciale</button>}
              </div>
            </div>
             <div className="row">
             {
             (toogle||props.showlistpalestre)&&
             palestreassociate.map(palestraSocio =>(
               <PalestraAssociataSelect key={palestraSocio.id} soc={palestraSocio} link={props.link}
               setkeytorefresh={setkeytorefresh} />
              )
              )
             }
            
            </div>
            </div>
            }
            {(!palestreassociate||palestreassociate.lenght===0)&&"Nessuna attivit&agrave; commerciale associata"}

            </div>
        );
  }