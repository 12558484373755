import React, { useDebugValue, useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import RigaCalendario from "../calendario/RigaCalendario";
import {connect, useSelector} from 'react-redux'
import serverInfo from "../util/globals";

export default function Calendario (props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const token = useSelector(state => state.gestoreUtente.token);

  const [calendari, setcalendari]=useState([]);

    useEffect(() => {
        console.log("RiquadroCalendari")
        if(!props.calendario){
          const formData = new FormData();
          formData.append('website', global.config.domain);
          formData.append('email',email);
          formData.append('lic','000');
          formData.append('palestra',palestra.id);
          
          axios.post(serverInfo.namesec+"/cal/getCalendari", formData,
			{
        withCredentials: true,
        credentials:'include',
			  headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+token
			  },
			},)
            .then(response=>{
              
              console.log(response);
              setcalendari(response.data)
              localStorage.setItem('calendari',response.data)
              if (!localStorage.getItem('calendario')){
                localStorage.setItem('calendario',Object.values(response.data)[0])
                if (props.link){
                  window.location.href=props.link
                }
              }
              
            })
          }
	},[email, palestra]);

      return (
          <div>
        {
            calendari&&Array.isArray(calendari)&&calendari.length>0?
            calendari.map(calendario=>(
              <RigaCalendario key={calendario.id} link={props.link} 
              calendario={calendario} 
              setcalendario={props.setCalendario}
              />
            ))
            :"Nessun calendario presente"
          }
          </div>
      );
        }